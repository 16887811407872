import "./index.css";
import "./responsive-home.css";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { Link } from "react-router-dom";

//Integration Functions
import { guidedSelling } from "./functions/integration_function/index";
import { country_request } from "./functions/integration_function/index";

// Imports MaterialUI icons
/* import AddBoxIcon from '@mui/icons-material/AddBox'; */

//Style functions
import { creating_box_of_country } from "./functions/style_function/style";
import { clean_datas_inserted_per_user } from "./functions/style_function/style";
import { animation } from "./functions/style_function/style";
import { physycal_accordion_home } from "./functions/style_function/style";
import { virtual_accordion_home } from "./functions/style_function/style";
import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import SellingCard from "../../components/selling_card/index";
import { exhibit_chip_checkbox } from "./functions/style_function/style";
import { change_send_text } from "./functions/style_function/style";
import { exhibit_search_historic } from "./functions/style_function/style";
import { clear_datas_of_last_search } from "./functions/style_function/style";

const error = (message) => toast.error(message);

function Compra() {
  const [plan, setPlan] = useState([]);
  const [countries_to_exhibit_on_the_dropdown, setExhbition_country] = useState([]);
  let selected_countries = []; //this array will store all the  countries that the user texted and add.




  function adding_countries_to_the_search() {
    let selected_country = document.querySelector("#country").value;

    if(selected_country === "") {
      error("É necessário escolher um país, e adiciona-lo antes de realizar a busca!");
      return;
    }

    selected_countries.push(selected_country);
    document.getElementById("country").value = "";
    creating_box_of_country(selected_country, selected_countries);
  }





  async function searching_countries() {
    let accept_esim = document.getElementById("models");

    if (accept_esim.value === "" || selected_countries[0] === undefined || (document.querySelector('input[name="chip"]:checked') === null && accept_esim.value.includes("true"))) {
      error("Todos os campos são obrigatórios.");
      return;
    }

    let loading_div = document.querySelector("#load");
    loading_div.style.display = "block";

    let exhibit_plans = document.querySelector("#exhibiting_plans");
    exhibit_plans.style.display = "none";

    let chip_type = document.querySelector('input[name="chip"]:checked') ? document.querySelector('input[name="chip"]:checked').value : "chip_fisico";
    let req = await guidedSelling(selected_countries,chip_type); 
    loading_div.style.display = "none";


      if(req.data.products[0] === undefined) {
        error("O mysimtravel não possui cobertura no(s) país(es) digitado(s).");
        setPlan([]);
        clean_datas_inserted_per_user();
        return;

      }else {
        exhibit_plans.style.display = "flex";
        setPlan(req.data.products);
      }


    exhibit_search_historic(selected_countries, document.getElementById("models").value.includes("/S") ? "SAMSUNG GALAXY S20, S20+, S21, S22, Z Ultra ou superiores" : document.getElementById("models").value.includes("/I") ?"Celulares (desbloqueados) iPhone XR, XS, 11, 12, 13 ou superiores" :"Outros modelos de celulares", document.querySelector('input[name="chip"]:checked') ? document.querySelector('input[name="chip"]:checked').value: "Chip físico");
    change_send_text(document.querySelector('input[name="chip"]:checked') ? document.querySelector('input[name="chip"]:checked').value : "Chip físico");
    clean_datas_inserted_per_user();

    return;
  }

  setTimeout(() => {
    exhibit_chip_checkbox();

    /* document.getElementById("country").addEventListener('keypress', function(e){console.log("Event")
      if(e.key === "Enter"){
        adding_countries_to_the_search()
      }
    }, false);  */
  }, 1000);


  async function catching_counntries() {
    let req = await country_request();
    setExhbition_country(req);
  }
  

  useEffect(() => {
    catching_counntries();
  }, []);

  return (
    <div className="body-home">
      <ToastContainer />

      <div className="homepage homepageresponsive">
      <Header />
      {/* Conteúdo da página inicial */}

      <div class="banner_header">
        <div className="div_slide_carousel-1">
          <div className="banner_text_content">

            <h1 className="text-banner-content">
              Compartilhe os <span className="span-inicio">melhores</span><br/> momentos da sua<br/>  <span className="span-inicio">viagem</span>
            </h1>
            <p className="text-banner-content-p">
            Conectando <span className="span-inicio">Você</span> ao <span className="span-inicio">Mundo</span>: Cobertura em +180<br/> Países, Tarifas em Reais e Um Chip para Suas<br/>  Viagens Futuras!
            </p>

         
          </div>
          <div className="mockup_chip">
      
            <img
              src="/assets/img/imagemnovafundo.svg"
              alt="FUNDO IMAGEM CHIP"
              className="imghomepag"
            />
          
            
          </div>
        </div>

        <div className="div_slide_carousel-2">
          <div className="banner_text_content">
            <h1 className="text-banner-content">
              O MELHOR CHIP INTERNACIONAL DO MERCADO
            </h1>
            <p>
              Fique conectado no mundo todo com o chip internacional{" "}
              <span>mysimtravel</span>
            </p>
          </div>
          <div className="mockup_chip">
            <img
              src="/assets/img/mockup-chip-homepage.svg"
              alt="FUNDO IMAGEM CHIP"
            />
          </div>
        </div>
      </div>

      <article class="info-header">
        <div class="info-div">
          <div class="ano-de-mercado">
            <h1>+8</h1>
            <h2>
              ANOS DE
              <br />
              MERCADO
            </h2>
          </div>

          <div class="clientes-satisfeitos">
            <h1>+70 MIL</h1>
            <h2>
              CLIENTES
              <br />
              SATISFEITOS
            </h2>
          </div>

          <div class="paises-cobertura">
            <h1>+180</h1>
            <h2>
              PAÍSES COM
              <br />
              COBERTURA
            </h2>
          </div>
        </div>
      </article>
      <div className="div-main">



      <div className="simfinity_new_product_shelf_home">
              <h1 className="simfinity_new_product_shelf_title_home">Lançamento de novos produtos <span className="orange-text">mysimtravel-simfinity</span> </h1>

              <div className="selling_card_flex_box_home">
                <div className="simfinity-plans-home">
                  <SellingCard title="Planos USA/Europa" image="/assets/img/mysim_simfinity_usa_eur.svg"/>{/* Usa eu */}
                  <SellingCard title="Planos Japão" image="/assets/img/mysim_simfinity_japao.svg"/>{/* Japan */}
                  <SellingCard title="Planos Dubai" image="/assets/img/mysim_simfinity_dubai.svg"/>{/*Dubai */}
                  <SellingCard title="Planos Austrália" image="/assets/img/mysim_simfinity_australia.svg"/>{/* Usa eu */}
                </div>
              </div>

          </div>
    



        <div class="section-input" id="section-input">
          <div class="input-s" id="input-s">
            <label for="Search" className="label_search">
              <div className="div-label-search">
              <h1 className="responsivoh1label">
            Qual será o seu próximo <span>destino?</span>
              </h1>
              </div>
            </label>

            <div class="inputebutton">
              <input
                type="search"
                name="country"
                id="country"
                placeholder="Digite um país por vez e clique em adicionar"
                class="input-search"
                list="countries"
                onClick={clear_datas_of_last_search}
              ></input>

              <datalist id="countries">
                {countries_to_exhibit_on_the_dropdown.map((country) => {
                  return <option value={country}></option>;
                })}
              </datalist>

              <input
                type="submit"
                value="Adicionar"
                class="button"
                onClick={adding_countries_to_the_search}
              />
              {/* <button type="submit"
              class="button"
              onClick={adding_countries_to_the_search}>
              
              <h3 className='button_add_h3'>Adicionar</h3>
              <h3 className='button_add_h3_plus'>+</h3>
              
            </button> */}
            </div>

            <div id="selected_countries" className="selected_countries">
              <div id="before" className="ghost"></div>
            </div>

            <select name="modelocelular" className="input-phone" id="models">
              <option checked value="">
                Escolha o modelo do seu celular
              </option>
              <option value={"true/S"}>
                Celulares (desbloqueados) SAMSUNG GALAXY S20, S20+, S21, S22, Z
                Ultra ou superiores
              </option>
              <option value={"true/I"}>
                Celulares (desbloqueados) iPhone XR, XS, 11, 12, 13 ou
                superiores
              </option>
              <option value={false}>Outros modelos de celulares</option>
            </select>

            <div className="input-selectmodelchip">
              <div className="h1-selectmodelchip">
                <h1>Qual tecnologia de chip que você deseja ?</h1>
              </div>
              <div className="checkbox-modelchip">
                <input
                  type="RADIO"
                  id="chipVirtual"
                  name="chip"
                  defaultValue="Chip virtual (eSIM)"
                  required
                />
                Chip virtual (eSIM)
                <br />
                <input
                  type="RADIO"
                  id="chipFisico"
                  name="chip"
                  defaultValue="Chip físico"
                  required
                />{" "}
                Chip físico
                <br />
              </div>
            </div>


            <div className="div-button-labelsearch">
            <button class="buscar-button" onClick={searching_countries}>
              Buscar
            </button>

            </div>

            <div className="search_historic" id="search_historic"></div>
          </div>
        </div>

        <div className="load_parent">
          <div className="loading" id="load"></div>
        </div>

        <div className="fernando" id="exhibiting_plans">
          {plan.map(
            (pl) => {
              return (
                <div className="plans-fernando">
                  {/* <div className='plan_setting'> */}
                  <a
                    rel="noopener noreferrer"
                    href={`https://mystore.mysimtravel.com/${"mysimtravel_geral"}?integration=true&productId=${
                      pl.id
                    }&quantity=1`}
                    target="_blank"
                  >
                    <img alt="" src={pl.product.image.medium} />{" "}
                  </a>

                  <div className="plan_price">
                    R$
                    {pl.product.value.substring(
                      0,
                      pl.product.value.indexOf(".")
                    )}
                  </div>

                  {/* <div className="plan_preferences"> 

                      <div className="div_inside_the_plan_preferences_div">
                      <AddBoxIcon cursor="pointer" />
                      <p className='value_plan_car'>1</p>
                      <AddBoxIcon cursor="pointer"/>
                      </div>

                      <div className='button_div_plan_preferences'> 
                      <button className='button_preferences'>Comprar</button>
                      </div>
                      </div> */}

                  {/* </div> */}
                </div>
              );
            } //end of function map
          )}
        </div>

        <h1 className="send-text-physical-chip" id="send-text-physical-chip">
          <span>
            TODOS OS PLANOS DE INTERNET POSSUEM VALIDADE EM DIAS OU QUANDO
            ATINGIR O LIMITE DA INTERNET CONTRATADA
          </span>{" "}
          <br />
          <span>Temos entregas via:</span> Carta Registrada 7-12 dias úteis,
          Sedex 2-7 dias úteis
          <br /> e também a possibilidade de retirada no aeroporto* (confira a
          lista na "forma de entrega")
        </h1>

        <h1 className="send-text-virtual-chip" id="send-text-virtual-chip">
          <span>
            TODOS OS PLANOS DE INTERNET POSSUEM VALIDADE EM DIAS OU QUANDO
            ATINGIR O LIMITE DA INTERNET CONTRATADA
          </span>{" "}
          <br />A entrega do chip virtual (eSIM) é feita via e-mail após o
          contato da nossa equipe de suporte técnico.
        </h1>



        



        <div className="grid-info">
          <h1 className="grid-h1">
            O que é o chip internacional <span>mysimtravel</span> ?
          </h1>
          <div className="mockup-mobile">
            <img src="/assets/img/mockup-mobile.svg" alt="Mockup mobile" />
          </div>
          <div className="grid-infolayout">
            <div className="grid-chipfisico">
              <h1>Chip Físico e eSIM</h1>
              <p>
                Chip físico (Regular, Micro e Nano)
                <br /> Chip virtual eSIM
                <br /> Serviços de dados, ligações e SMS
              </p>
            </div>

            <div className="grid-naodescartavel">
              <h1>Não é descartável</h1>
              <p>
                Pode usar em todas suas viagens
                <br />
                <span> O mysimtravel não perde a validade</span> <br /> Apenas
                recarregar e usar
              </p>
            </div>

            <div className="grid-cobertura">
              <h1>Cobertura Global</h1>
              <p>
                Funciona em +180 países <br /> Multi-operadora em diversos
                países
              </p>
            </div>

            <div className="grid-beneficio">
              <h1>Custo-Benefício</h1>
              <p>
                <span>Tarifas em reais</span>
                <br /> Os créditos do chip não expiram.
                <br /> Sem custo de manutenção mensal
                <br />
              </p>
            </div>

            <div className="grid-sup">
              <h1>Suporte Técnico</h1>
              <p>
                <span>Equipe online 22h todos os dias</span>
                <br /> Através do whatsapp, chat online, e-mail
                <br /> Português, Espanhol e Inglês
                <br />
              </p>
            </div>

            <div className="grid-controlapp">
              <h1>Controle pelo App</h1>
              <p>
                Gestão pelo app{" "}
                <span className="span_faq">mysimtravel FORYOU.</span> <br />
                <span>Ative aqui no Brasil pelo app</span>
                <br /> Consulte seu consumo de internet
                <br /> Recarga em reais pelo app
              </p>
            </div>
          </div>
        </div>

        <section className="flex-infochip">
          <h1>Presença em todo território brasileiro!</h1>
          <div className="grid-infochip">
            <div className="ul-infochip">
              <ul>
                <li>
                  <h1>
                    Compre aqui no Brasil <span> antes</span> da sua viagem
                  </h1>
                </li>
                <li>
                  <h1>
                    <span>+700</span> revendas autorizadas em todo o brasil
                  </h1>
                </li>
                <li>
                  <h1>Compre online e retire no aeroporto</h1>
                </li>
                <li>
                  <h1>eSIM entregue no mesmo dia por email</h1>
                </li>
              </ul>

              <Link to="/distribuidores">
                {" "}
                <h1 className="infochip-comprar">Onde Comprar</h1>{" "}
              </Link>
            </div>

            <div className="info-chipimg">
              <img
                src="/assets/img/mockup-2-simcard.svg"
                alt="Mockup info"
                className="chip-desktop"
              />
              <img
                src="/assets/img/chip-mockup32.svg"
                alt="Chip responsive"
                className="chip-responsive"
              />
            </div>
          </div>
        </section>

        <section className="grid-paiseshome">
          <div className="layout-paiseshome">
            <h1>
              Alguns dos destinos <span>mais procurados </span>:
            </h1>
            <div className="grid-layoutpaiseshome">
              <a
                href="https://mystore.mysimtravel.com/mysimtravel-simfinity"
                rel="noopener noreferrer"
                target="_blank"
                className="link-country"
              >
                <div className="pais-usa">
                  <div className="card_div4">
                    <h2>Comprar</h2>
                  </div>

                  <div className="div_content_usa">
                    <h2>Estados Unidos</h2>
                  </div>
                </div>
              </a>

              <a
                href="https://mystore.mysimtravel.com/mysimtravel-simfinity"
                rel="noopener noreferrer"
                target="_blank"
                className="link-country"
              >
                <div className="pais-dubai">
                  <div className="card_div4">
                    <h2>Comprar</h2>
                  </div>
                  <div className="div_content_dubai">
                    <h2>Emirados Árabes</h2>
                  </div>
                </div>
              </a>

              <a
                href="https://mystore.mysimtravel.com/mysimtravel-simfinity"
                rel="noopener noreferrer"
                target="_blank"
                className="link-country"
              >
                <div className="pais-franca">
                  <div className="card_div4">
                    <h2>Comprar</h2>
                  </div>

                  <div className="div_content_franca">
                    <h2>França</h2>
                  </div>
                </div>
              </a>

              <a
                href="https://mystore.mysimtravel.com/mysimtravel-simfinity"
                rel="noopener noreferrer"
                target="_blank"
                className="link-country"
              >
                <div className="pais-grecia">
                  <div className="card_div4">
                    <h2>Comprar</h2>
                  </div>

                  <div className="div_content_grecia">
                    <h2>Portugal</h2>
                  </div>
                </div>
              </a>

              <a
                href="https://mystore.mysimtravel.com/mysimtravel-simfinity"
                rel="noopener noreferrer"
                target="_blank"
                className="link-country"
              >
                <div className="pais-turquia">
                  <div className="card_div4">
                    <h2>Comprar</h2>
                  </div>

                  <div className="div_content_turquia">
                    <h2>japão</h2>
                  </div>
                </div>
              </a>

              <a
                href="https://mystore.mysimtravel.com/mysimtravel-simfinity"
                rel="noopener noreferrer"
                target="_blank"
                className="link-country"
              >
                <div className="pais-egito">
                  <div className="card_div4">
                    <h2>Comprar</h2>
                  </div>

                  <div className="div_content_egito">
                    <h2>Austrália</h2>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>

        <section className="card_testimonial">
          <div className="card_testimonial_text1">
            <h1>Depoimentos de nossos clientes</h1>
          </div>
          <div className="card_content_testimonial">
            <div className="cards card_testimonial_dp1">
              <img src="/assets/img/depoimento-1.svg" alt="Depoimentos 1" />
            </div>
            <div className="cards card_testimonial_dp2">
              <img src="/assets/img/depoimento-2.svg" alt="Depoimentos 2" />
            </div>
            <div className="cards card_testimonial_dp3">
              <img src="/assets/img/depoimento-3.svg" alt="Depoimentos 3" />
            </div>
          </div>
        </section>

        <div className="link_testimonial_home">
          <h1 className="link_testimonial_home_h1">
            Feedback fornecido através do{" "}
            <a href="https://www.google.com/maps/place/mysimtravel/@-23.5954928,-46.6384103,17z/data=!4m7!3m6!1s0x94ce5b0044f1f3af:0x401a1d4904c02018!8m2!3d-23.5954928!4d-46.6362216!9m1!1b1">
              Google
            </a>
          </h1>
        </div>

        <section className="article_links">
          <h1>Quer conhecer mais sobre os nossos serviços?</h1>
          <Link to="/solucoes-corporativas" className="a_link_articlelinks">
            Soluções corporativas
          </Link>
          <Link to="/SejaUmaRevenda" className="a_link_articlelinks">
            Revenda Autorizada
          </Link>
          <Link to="/m2m-iot" className="a_link_articlelinks">
            M2M & IoT
          </Link>
        </section>

        <section className="faq_homepage" id="faq_homepage">
          <div className="article_faq_homepage">
            <h1>
              Ainda tem dúvida sobre o{" "}
              <span className="span_faq">mysimtravel</span> ?
            </h1>
            <h1>Confira o nosso FAQ</h1>
          </div>

          <div className="faq_active">
            <div
              className="faq_chip_fisico"
              id="option-chip-fisico-home"
              onClick={physycal_accordion_home}
            >
              <h1 id="text-option-fisico-home">Chip Físico</h1>
            </div>

            <div
              className="faq_chip_virtual"
              id="option-chip-virtual-home"
              onClick={virtual_accordion_home}
            >
              <h1 id="text-option-virtual-home">eSIM</h1>
            </div>
          </div>

          <div
            className="div_section_chip_fisico_homepage"
            id="section_chip_fisico_home"
          >
            <div class="accordion-homepage" id="accordion-fisico-home">
              <div class="accordion-item-homepage">
                <div
                  class="accordion-item-header-homepage"
                  id="duvidawhatsapp"
                  onClick={() => {
                    animation("duvidawhatsapp");
                  }}
                >
                  <h1>Como é a configuração?</h1>
                </div>
                <div class="accordion-item-body-homepage">
                  <div class="accordion-item-body-content-homepage">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Assim que receber o chip é necessário fazer alguns
                        ajustes no seu celular, isso pode ser feito antes da
                        viagem. Você consegue acompanhar o processo por aqui{" "}
                        <Link to="/como-funciona" className="link-homepage">
                          {" "}
                          Configurações
                        </Link>{" "}
                        ou chamar nosso suporte pelo WhatsApp
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage" id="accordion-fisico-home">
              <div class="accordion-item-homepage">
                <div
                  class="accordion-item-header-homepage"
                  id="vouteroutrochip"
                  onClick={() => {
                    animation("vouteroutrochip");
                  }}
                >
                  <h1>O chip é entregue nos 3 tamanhos?</h1>
                </div>
                <div class="accordion-item-body-homepage">
                  <div class="accordion-item-body-content-homepage">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Sim, no tamanho regular, micro e nano.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage" id="accordion-fisico-home">
              <div class="accordion-item-homepage">
                <div
                  class="accordion-item-header-homepage"
                  id="inserirchip"
                  onClick={() => {
                    animation("inserirchip");
                  }}
                >
                  <h1>Vou ter outro chip?</h1>
                </div>
                <div class="accordion-item-body-homepage">
                  <div class="accordion-item-body-content-homepage">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Sim, você recebe um chip internacional com DDI +372.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage" id="accordion-fisico-home">
              <div class="accordion-item-homepage">
                <div
                  class="accordion-item-header-homepage"
                  id="validadechip"
                  onClick={() => {
                    animation("validadechip");
                  }}
                >
                  <h1>Qual a validade do chip?</h1>
                </div>
                <div class="accordion-item-body-homepage">
                  <div class="accordion-item-body-content-homepage">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                      O Chip mysimtravel deve ser registrado em um operadora conveniada pelo menos uma vez a cada 12 meses para manter-se válido. Passado este período a operadora poderá considerar como chip perdido e então desativá-lo. Ocorrendo esta situação, entre em contato com nosso atendimento que iremos consultar a operadora. Estando desativado poderemos fornecer outro chip com outro número.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage" id="accordion-fisico-home">
              <div class="accordion-item-homepage">
                <div
                  class="accordion-item-header-homepage"
                  id="ativacaodata"
                  onClick={() => {
                    animation("ativacaodata");
                  }}
                >
                  <h1>Tem data de ativação? </h1>
                </div>
                <div class="accordion-item-body-homepage">
                  <div class="accordion-item-body-content-homepage">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Não. Com o <span className="span_faq">mysimtravel</span>{" "}
                        você mesmo ativa o plano pelo nosso app{" "}
                        <span className="span_faq">mysimtravel FORYOU</span>.
                        Nele você consegue acompanhar o seu consumo de internet,
                        ativar o plano e recarregar seus créditos.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage" id="accordion-fisico-home">
              <div class="accordion-item-homepage">
                <div
                  class="accordion-item-header-homepage"
                  id="controlechip"
                  onClick={() => {
                    animation("controlechip");
                  }}
                >
                  <h1>Por onde eu tenho controle do meu chip?</h1>
                </div>
                <div class="accordion-item-body-homepage">
                  <div class="accordion-item-body-content-homepage">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Você faz toda a gestão do seu chip através do nosso app{" "}
                        <span className="span_faq">
                          mysimtravel FORYOU.
                          <br />
                        </span>
                        Nele é feito a ativação do seu plano de internet (no dia
                        da viagem), acompanha o seu consumo e faz recargas.
                        Disponivel na Play Store e App Store.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage" id="accordion-fisico-home">
              <div class="accordion-item-homepage">
                <div
                  class="accordion-item-header-homepage"
                  id="comprarcomant"
                  onClick={() => {
                    animation("comprarcomant");
                  }}
                >
                  <h1>Posso comprar o chip com antecedência?</h1>
                </div>
                <div class="accordion-item-body-homepage">
                  <div class="accordion-item-body-content-homepage">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Com o mysimtravel é possivel se planejar tranquilamente,
                        pois você mesmo faz a ativação no dia de sua viagem e
                        somente após isso, o prazo do plano começa a contar.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage" id="accordion-fisico-home">
              <div class="accordion-item-homepage">
                <div
                  class="accordion-item-header-homepage"
                  id="rotearchip"
                  onClick={() => {
                    animation("rotearchip");
                  }}
                >
                  <h1>Consigo compartilhar minha internet (rotear)? </h1>
                </div>
                <div class="accordion-item-body-homepage">
                  <div class="accordion-item-body-content-homepage">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Sim, você consegue compartilhar a sua internet
                        normalmente, não há nenhuma restrição.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage" id="accordion-fisico-home">
              <div class="accordion-item-homepage">
                <div
                  class="accordion-item-header-homepage"
                  id="colocaremoutrocelular"
                  onClick={() => {
                    animation("colocaremoutrocelular");
                  }}
                >
                  <h1>Posso tirar do meu celular e colocar em outro? </h1>
                </div>
                <div class="accordion-item-body-homepage">
                  <div class="accordion-item-body-content-homepage">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Pode sim, basta você configurar o outro celular em que
                        irá usar.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage" id="accordion-fisico-home">
              <div class="accordion-item-homepage">
                <div
                  class="accordion-item-header-homepage"
                  id="enviochip"
                  onClick={() => {
                    animation("enviochip");
                  }}
                >
                  <h1>Como recebo meu chip?</h1>
                </div>
                <div class="accordion-item-body-homepage">
                  <div class="accordion-item-body-content-homepage">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Nós enviamos à você via Correios por Carta Registrada
                        (7-12 dias úteis) ou Sedex (2-7 dias úteis).
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage" id="accordion-fisico-home">
              <div class="accordion-item-homepage">
                <div
                  class="accordion-item-header-homepage"
                  id="aeroportoschip"
                  onClick={() => {
                    animation("aeroportoschip");
                  }}
                >
                  <h1>Posso retirar no aeroporto? </h1>
                </div>
                <div class="accordion-item-body-homepage">
                  <div class="accordion-item-body-content-homepage">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Na hora de escolher como receber o chip, você consegue
                        selecionar em qual aeroporto gostaria de retirar.
                        Lembrando que, nos aeroportos você só consegue retirar o
                        seu chip. Caso queira comprar no local, acesse este link
                        e confira os locais disponiveis:
                        <Link
                          to="/chip-internet-aeroportos"
                          className="link-homepage"
                        >
                          {" "}
                          Aeroportos
                        </Link>
                        .
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage" id="accordion-fisico-home">
              <div class="accordion-item-homepage">
                <div
                  class="accordion-item-header-homepage"
                  id="cancelarcomprachip"
                  onClick={() => {
                    animation("cancelarcomprachip");
                  }}
                >
                  <h1 className="h1_accordion_item">
                    Posso efetuar o cancelamento da minha compra?
                  </h1>
                </div>
                <div class="accordion-item-body-homepage">
                  <div class="accordion-item-body-content-homepage">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Você tem até 7 dias úteis para cancelar a sua compra,
                        mas caso você já tenha ativado seu plano, isso não será
                        mais possível.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* FAQ VIRTUAL */}

          <div
            className="div_section_chip_virtual_home"
            id="section_chip_virtual_home"
          >
            <div class="accordion-homepage-virtual" id="accordion-virtual-home">
              <div class="accordion-item-homepage-virtual">
                <div
                  class="accordion-item-header-homepage-virtual"
                  id="oqueeesim"
                  onClick={() => {
                    animation("oqueeesim");
                  }}
                >
                  <h1>O que é eSIM ?</h1>
                </div>
                <div class="accordion-item-body-homepage-virtual">
                  <div class="accordion-item-body-content-homepage-virtual">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer-virtual">
                        O eSIM (Chip Virtual) te possibilita de ter um chip de
                        dados e voz sem nem mesmo precisar do chip físico. A
                        instalação do eSIM é através da leitura de um QRCode que
                        a nossa equipe envia à você após a sua compra.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage-virtual" id="accordion-virtual-home">
              <div class="accordion-item-homepage-virtual">
                <div
                  class="accordion-item-header-homepage-virtual"
                  id="aparelhoscompativeis"
                  onClick={() => {
                    animation("aparelhoscompativeis");
                  }}
                >
                  <h1>Todos os aparelhos são compatíveis?</h1>
                </div>
                <div class="accordion-item-body-homepage-virtual">
                  <div class="accordion-item-body-content-homepage-virtual">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Não, somente os aparelhos mais modernos são compatíveis
                        com o eSIM. Você consegue conferir nessa lista:
                        <br />
                        iPhones: iPhone 14, 14 Plus, 14 Pro, 14 Pro Max iPhone
                        13, 13 mini, 13 Pro, 13 Pro Max iPhone 12, 12 mini, 12
                        Pro, 12 Pro Max iPhone 11, 11 Pro, 11 Pro Max iPhone XS,
                        XS Max, XR iPhone SE (2020)
                        <br />
                        <br />
                        Samsung: Galaxy S20 Galaxy S20+ Galaxy S20 Ultra Galaxy
                        S21 Galaxy S21+ 5G Galaxy S21+ Ultra 5G Galaxy S22
                        Galaxy S22+ Galaxy S22 Ultra Galaxy Note 20 Galaxy Note
                        20 Ultra 5G Galaxy Fold Galaxy Z Fold2 5G Galaxy Z Fold3
                        5G Galaxy Z Flip Galaxy Z Flip 5G Galaxy Z Flip3 5G
                        <br />
                        <br />
                        Motorola: Razr Razr 5G
                        <br />
                        <br />
                        🚫Xiaomi
                        <br />
                        <br />
                        Outros modelos:
                        <br />
                        Google Pixel 2 e 2 XL Google Pixel 3 e 3 XL Google Pixel
                        3a e 3a XL Google Pixel 4 e 4 XL Google Pixel 4a 5G
                        Google Pixel 5 Google Pixel 5a Google Pixel 6 Google
                        Pixel 6 Pro Google Pixel 6a
                        <br />
                        <br />
                        Huawei P40 Huawei P40 Pro Huawei Mate 40 Pro
                        <br />
                        <br />
                        Oppo Find X3 Pro Oppo Reno 5A Oppo Reno6 Pro 5G Oppo
                        Find X5 Oppo Find X5 Pro Oppo A55s
                        <br />
                        <br />
                        Sony Xperia 10 III Lite Sony Xperia 1 IV Sony Xperia 10
                        IV
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage-virtual" id="accordion-virtual-home">
              <div class="accordion-item-homepage-virtual">
                <div
                  class="accordion-item-header-homepage-virtual"
                  id="transferiresim"
                  onClick={() => {
                    animation("transferiresim");
                  }}
                >
                  <h1>Consigo transferir o eSIM para outro celular? </h1>
                </div>
                <div class="accordion-item-body-homepage-virtual">
                  <div class="accordion-item-body-content-homepage-virtual">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Uma vez instalado no seu celular, não é possivel
                        transferir para um outro aparelho. Nesse caso,
                        recomendamos que faça a instalação somente no aparelho
                        em que deseja utilizar.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage-virtual" id="accordion-virtual-home">
              <div class="accordion-item-homepage-virtual">
                <div
                  class="accordion-item-header-homepage-virtual"
                  id="validadeesim"
                  onClick={() => {
                    animation("validadeesim");
                  }}
                >
                  <h1>O eSIM possui validade? </h1>
                </div>
                <div class="accordion-item-body-homepage-virtual">
                  <div class="accordion-item-body-content-homepage-virtual">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Assim como o chip físico, ele pode ser usado em futuras
                        viagens.{" "}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage-virtual" id="accordion-virtual-home">
              <div class="accordion-item-homepage-virtual">
                <div
                  class="accordion-item-header-homepage-virtual"
                  id="receberesim"
                  onClick={() => {
                    animation("receberesim");
                  }}
                >
                  <h1>Como eu recebo meu eSIM? </h1>
                </div>
                <div class="accordion-item-body-homepage-virtual">
                  <div class="accordion-item-body-content-homepage-virtual">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Ao finalizar a compra, nossa equipe envia o eSIM no
                        mesmo dia em seu email e te auxilia na instalação do
                        chip.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage-virtual" id="accordion-virtual-home">
              <div class="accordion-item-homepage-virtual">
                <div
                  class="accordion-item-header-homepage-virtual"
                  id="diainstalacaoesim"
                  onClick={() => {
                    animation("diainstalacaoesim");
                  }}
                >
                  <h1>Em qual dia eu faço a instalação do eSIM? </h1>
                </div>
                <div class="accordion-item-body-homepage-virtual">
                  <div class="accordion-item-body-content-homepage-virtual">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Recomendamos que seja feita no mesmo dia em que receber
                        o chip, para que no dia da viagem você faça apenas a
                        ativação do plano.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage-virtual" id="accordion-virtual-home">
              <div class="accordion-item-homepage-virtual">
                <div
                  class="accordion-item-header-homepage-virtual"
                  id="removeresim"
                  onClick={() => {
                    animation("removeresim");
                  }}
                >
                  <h1>Preciso remover o chip físico para instalar o eSIM?</h1>
                </div>
                <div class="accordion-item-body-homepage-virtual">
                  <div class="accordion-item-body-content-homepage-virtual">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Não é necessário.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage-virtual" id="accordion-virtual-home">
              <div class="accordion-item-homepage-virtual">
                <div
                  class="accordion-item-header-homepage-virtual"
                  id="maisumchipnocelular"
                  onClick={() => {
                    animation("maisumchipnocelular");
                  }}
                >
                  <h1>Posso ter mais de 1 eSIM no meu celular?</h1>
                </div>
                <div class="accordion-item-body-homepage-virtual">
                  <div class="accordion-item-body-content-homepage-virtual">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Sim, você pode ter até 8 números diferentes no seu
                        celular.{" "}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage-virtual" id="accordion-virtual-home">
              <div class="accordion-item-homepage-virtual">
                <div
                  class="accordion-item-header-homepage-virtual"
                  id="comprarduranteviagem"
                  onClick={() => {
                    animation("comprarduranteviagem");
                  }}
                >
                  <h1>Posso comprar durante a viagem? </h1>
                </div>
                <div class="accordion-item-body-homepage-virtual">
                  <div class="accordion-item-body-content-homepage-virtual">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Sim, se seu celular for compatível, você efetua a
                        compra, te enviamos o eSIM e você já começa a usar no
                        mesmo ato.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage-virtual" id="accordion-virtual-home">
              <div class="accordion-item-homepage-virtual">
                <div
                  class="accordion-item-header-homepage-virtual"
                  id="cancelamentoviagem"
                  onClick={() => {
                    animation("cancelamentoviagem");
                  }}
                >
                  <h1>Posso efetuar o cancelamento da minha compra? </h1>
                </div>
                <div class="accordion-item-body-homepage-virtual">
                  <div class="accordion-item-body-content-homepage-virtual">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        você tem até 7 dias úteis para cancelar a sua compra,
                        mas caso você já tenha instalado o chip no seu celular
                        ou ativado seu plano, isso não será mais possível.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage-virtual" id="accordion-virtual-home">
              <div class="accordion-item-homepage-virtual">
                <div
                  class="accordion-item-header-homepage-virtual"
                  id="dataesimativacao"
                  onClick={() => {
                    animation("dataesimativacao");
                  }}
                >
                  <h1>Tem data de ativação? </h1>
                </div>
                <div class="accordion-item-body-homepage-virtual">
                  <div class="accordion-item-body-content-homepage-virtual">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Não. Com o <span className="mysim">mysimtravel</span>{" "}
                        você mesmo ativa o plano pelo nosso app{" "}
                        <span className="span_faq">mysimtravel FORYOU</span>.
                        Nele você consegue acompanhar o seu consumo de internet,
                        ativar o plano e recarregar seus créditos.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-homepage-virtual" id="accordion-virtual-home">
              <div class="accordion-item-homepage-virtual">
                <div
                  class="accordion-item-header-homepage-virtual"
                  id="controlechipesim"
                  onClick={() => {
                    animation("controlechipesim");
                  }}
                >
                  <h1>Por onde eu tenho controle do meu chip?</h1>
                </div>
                <div class="accordion-item-body-homepage-virtual">
                  <div class="accordion-item-body-content-homepage-virtual">
                    <div class="accordion-item1-home">
                      <h1 className="accordion_text_layer">
                        Você faz toda a gestão do seu chip através do nosso app{" "}
                        <span className="span_faq">mysimtravel FORYOU! </span>
                        Nele você faz a ativação do seu plano de internet (no
                        dia da viagem), acompanha o seu consumo e faz recargas.
                        Está disponivel na Play Store e App Store.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ultimo_artigo_homepage">
            <h1 className="ultimo_texto_homepage">Contatos</h1>

            <div className="content_box_text_footer_homepage">
              <h2>Suporte</h2>
              <ul>
                <li>
                  <span>Whatsapp: </span>+55 (11) 98734-1818 (Suporte disponível
                  22 horas todos os dias das 06:00AM ás 04:00AM)
                </li>
                <li>
                  <span>Telefone: </span>(11) 2309-5655 - Ramal 2 (Disponível em
                  horário comercial)
                </li>
                <li>
                  <span>E-mail: </span>suporte.brasil@sgmobile.com.br
                </li>
              </ul>
            </div>

            <div className="content_box_text_footer_homepage">
              <h2>Vendas</h2>
              <ul>
                <li>
                  <span>Telefone: </span>(11) 2309-5655 - Ramal 1 (Disponível em
                  horário comercial)
                </li>
                <li>
                  <span>E-mail(1): </span>vendas.brasil@sgmobile.com.br
                </li>
                <li>
                  <span>E-mail(2): </span>vendas2.brasil@sgmobile.com.br
                </li>
              </ul>
            </div>

            <h1 className="h1_ultimo_homepage">
              Nossa equipe está <span className="mysim">sempre</span> a sua
              disposição!
            </h1>
          </div>
        </section>
      </div>

      <Footer />
    </div>
    </div>
  );
}

/* SITE DESENVOLVIDO POR GABRIEL & KAIQUE */
export default Compra;
